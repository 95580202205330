import { Box, Divider, Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Layout, Seo } from "../../components";
import "../../styles/screen.scss";

const seoBundle = {
  title: "LMAC Equipment Storage",
  subtitle: "JMF Steel Erectors",
  description: "Idaho Steel Construction Done Right",
  slug: "/projects/lmac",
};

const Project5 = ({ location }) => {
  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Flex
        maxW='1450px'
        h='100%'
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        align='center'
        justify='center'
        flexDirection='column'
      >
        <Heading
          as='h1'
          color='dkGrey'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["3em", "4em"]}
          fontFamily='heading'
          lineHeight={["100%", null, null, "120%"]}
          textAlign='center'
        >
          50' X 100' Shop and Equipment Storage
        </Heading>
        <Heading
          as='h2'
          color='dkGrey'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["1.5em", "2em"]}
          fontFamily='body'
          lineHeight={["120%"]}
          textAlign='center'
        >
          Agricultural Project in Midvale, Idaho
        </Heading>
        <Flex
          w='100%'
          my={["30px"]}
          h={["200px", null, "200px", "400px", "600px"]}
          justifyContent='center'
        >
          <StaticImage
            src='../../images/07.png'
            alt='White Shop in Midvale, Idaho'
            placeholder='blurred'
          />
        </Flex>
        <Heading
          as='h2'
          mt='40px'
          color='dkGrey'
          mb={["20px", null, null, "10px"]}
          fontWeight={800}
          fontSize={["1.5em", "2em"]}
          fontFamily='body'
          lineHeight={["120%"]}
          textAlign='center'
        >
          About the Project
        </Heading>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          LMAC Ranch needed a facilty to be both a shop and equipment storage.
          Steel construction really suits these kinds of projects. This project
          was designed to have many large bay doors, making for easy access.
        </Text>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          Contact us today to chat about your next project -- we'd love to help
          out in any way possible.
        </Text>
        <Divider maxW='600px' my='40px' orientation='horizontal' />
        <Heading as='h3' textAlign='center'>
          JMF Steel Erectors
        </Heading>
        <Heading as='h4' fontFamily='body' fontSize='16px'>
          An Idaho Company
        </Heading>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          We're a locally owned family business that has been operating in the
          Treasure Valley for decades. We have experience in all areas of steel
          construction and work hard to gain our customers trust and their
          business.
        </Text>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          Here at JMF, we make it our business to get to know you and your
          unique situation. Along with our services, we'll educate you on the
          process and practices necessary to create a successful project. Call
          us today!
        </Text>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          Steel construction done right.
        </Text>
        <Text
          mb='40px'
          mt='20px'
          fontSize={24}
          textAlign='center'
          maxW='800px'
          lineHeight='120%'
          fontWeight='bold'
          color='dkGrey'
        >
          Call us at <a href='tel:2088801062'>(208) 880-1062</a>
        </Text>
      </Flex>
    </Layout>
  );
};

export default Project5;
